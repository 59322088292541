import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_OFFENCE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/offence/api`

//////////////////////////////Inspection places //////////////////////////

//Get inspection places
const getInspectionPlaces = async (params) => {
  const response = await api.get(`${servicePrefix}/inspection-places`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllInspectionPlaces = async () => {
  const response = await api.get(`${servicePrefix}/inspection-places`)
  return response.data
}

//Get one inspection places
const getInspectionPlacesById = async (id) => {
  const response = await api.get(`${servicePrefix}/inspection-places/${id}`)
  return response
}

//Add inspection places
const addInspectionPlaces = async (individualInfo) => {
  const response = await api.post(`${servicePrefix}/inspection-places`, individualInfo)
  return response.data
}

//Update inspection places
const updateInspectionPlaces = async (inspectionData) => {
  const { id } = inspectionData
  const response = await api.patch(`${servicePrefix}/inspection-places/${id}`, inspectionData)
  return response.data
}

//Delete inspection places
const deleteInspectionPlaces = async (id) => {
  const response = await api.delete(`${servicePrefix}/inspection-places/${id}`)
  return response.data
}

//getAsPerPlaces

const inspectionPlacesByPlaces = async () => {
  const response = await api.get(`${servicePrefix}/inspection-places/get-inspection-places`)
  return response
}

////////////////////////////////////////////////////////////////////////////////

//////////////////////////////Traffic Divisions //////////////////////////

//Get Traffic Divisions
const getTrafficDivisions = async (params) => {
  const response = await api.get(`${servicePrefix}/traffic-divisions`, { params: stripEmptyObjects(params) })
  return response.data
}

// Get All Traffic Divisions
const getAllTrafficDivisions = async () => {
  const response = await api.get(`${servicePrefix}/traffic-divisions/all`)
  return response
}

//Get one Traffic Divisions
const getTrafficDivisionsById = async (id) => {
  const response = await api.get(`${servicePrefix}/traffic-divisions/${id}`)
  return response.data
}

//Add Traffic Divisions
const addTrafficDivisions = async (individualInfo) => {
  const response = await api.post(`${servicePrefix}/traffic-divisions`, individualInfo)
  return response.data
}

//Update Traffic Divisions
const updateTrafficDivisions = async (inspectionData) => {
  const { id } = inspectionData
  const response = await api.patch(`${servicePrefix}/traffic-divisions/${id}`, inspectionData)
  return response.data
}

//Delete Traffic Divisions
const deleteTrafficDivisions = async (id) => {
  const response = await api.delete(`${servicePrefix}/traffic-divisions/${id}`)
  return response.data
}

////////////////////////////////////////////////////////////////////////////////

//////////////////////////////Offences //////////////////////////

//Get Offences
const getOffences = async (params) => {
  const response = await api.get(`${servicePrefix}/offences`, { params: stripEmptyObjects(params) })
  return response.data
}

// Get AllOffences
const getAllOffences = async () => {
  const response = await api.get(`${servicePrefix}/offences/all`)
  return response
}

//Get one Offences
const getOffencesById = async (id) => {
  const response = await api.get(`${servicePrefix}/offences/${id}`)
  return response.data
}

//Add Offences
const addOffences = async (individualInfo) => {
  const response = await api.post(`${servicePrefix}/offences`, individualInfo)
  return response.data
}

//Update Offences
const updateOffences = async (inspectionData) => {
  const { id } = inspectionData
  const response = await api.patch(`${servicePrefix}/offences/${id}`, inspectionData)
  return response.data
}

//Delete Offences
const deleteOffences = async (id) => {
  const response = await api.delete(`${servicePrefix}/offences/${id}`)
  return response.data
}

////////////////////////////////////////////////////////////////////////////////

//////////////////////////////Traffic Infringements For Bhutanese //////////////////////////

//Get traffic-infringements
const getTrafficInfringements = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/traffic-infringements`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// Get All traffic-infringements
const getAllTrafficInfringements = async () => {
  const response = await api.get(`${servicePrefix}/traffic-infringements`)
  return response.data
}

//Get one traffic-infringements
const getTrafficInfringementsById = async (id) => {
  const response = await api.get(`${servicePrefix}/traffic-infringements/${id}`)
  return response.data
}

//Add traffic-infringements
const addTrafficInfringements = async (trafficInfringements) => {
  const response = await api.post(`${servicePrefix}/traffic-infringements`, trafficInfringements)
  return response.data
}

//Update traffic-infringements
const updateTrafficInfringements = async (trafficInfringements) => {
  const { id } = trafficInfringements
  const response = await api.patch(`${servicePrefix}/traffic-infringements/${id}`, trafficInfringements)
  return response.data
}

//Delete traffic-infringements
const deleteTrafficInfringements = async (id) => {
  const response = await api.delete(`${servicePrefix}/traffic-infringements/${id}`)
  return response.data
}

//Cancel Offence
const cancelOffenceForBhutanese = async (trafficInfringements) => {
  const { id } = trafficInfringements
  const response = await api.post(`${servicePrefix}/traffic-infringements/${id}/cancel-offence`, trafficInfringements)
  return response.data
}

//Payment

const paymentForTrafficInfringements = async (paymentInfo) => {
  const { id } = paymentInfo
  const response = await api.patch(`${servicePrefix}/traffic-infringements/${id}/payment`, paymentInfo)
  return response.data
}

const updateTrafficInfringementsAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/traffic-infringements/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const cancelOffenceForBhutaneseAttachments = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/traffic-infringements/${id}/cancellation-attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

////////////////////////////////////////////////////////////////////////////////

//////////////////////////////Traffic Infringements For Foreigners //////////////////////////

//Get traffic-infringements
const getTrafficInfringementsForForeigner = async (params) => {
  const response = await api.get(`${servicePrefix}/foreign-vehicle-infringements`, { params: stripEmptyObjects(params) })
  return response.data
}

// Get All traffic-infringements
const getAllTrafficInfringementsForForeigner = async () => {
  const response = await api.get(`${servicePrefix}/foreign-vehicle-infringements`)
  return response.data
}

//Get one traffic-infringements
const getTrafficInfringementsForForeignerById = async (id) => {
  const response = await api.get(`${servicePrefix}/foreign-vehicle-infringements/${id}`)
  return response.data
}

//Add traffic-infringements
const addTrafficInfringementsForForeigner = async (trafficInfringements) => {
  const response = await api.post(`${servicePrefix}/foreign-vehicle-infringements`, trafficInfringements)
  return response.data
}

//Update traffic-infringements
const updateTrafficInfringementsForForeigner = async (trafficInfringements) => {
  const { id } = trafficInfringements
  const response = await api.patch(`${servicePrefix}/foreign-vehicle-infringements/${id}`, trafficInfringements)
  return response.data
}

//Delete traffic-infringements
const deleteTrafficInfringementsForForeigner = async (id) => {
  const response = await api.delete(`${servicePrefix}/foreign-vehicle-infringements/${id}`)
  return response.data
}

//Cancel Offence
const cancelOffenceForForeigner = async (trafficInfringements) => {
  const { id } = trafficInfringements
  const response = await api.post(`${servicePrefix}/foreign-vehicle-infringements/${id}/cancel-offence`, trafficInfringements)
  return response.data
}

//Payment

const paymentForTrafficInfringementsForForeigner = async (paymentInfo) => {
  const { id } = paymentInfo
  const response = await api.patch(`${servicePrefix}/foreign-vehicle-infringements/${id}/payment`, paymentInfo)
  return response.data
}

const updateTrafficInfringementForeignerAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/foreign-vehicle-infringements/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const cancellationAttachmentForForeigners = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/foreign-vehicle-infringements/${id}/cancellation-attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const getTrafficInfringementsByLicenseNo = async (licenseNo) => {
  const response = await api.get(`${servicePrefix}/traffic-infringements/get-by-license/${licenseNo}`)
  return response.data
}

const getTrafficInfringementsByLicenseOrLearnerNo = async (licenseNo) => {
  const response = await api.get(`${servicePrefix}/traffic-infringements/get-by-license-or-learner-no/${licenseNo}`)
  return response.data
}

const getTrafficInfringementsByVehicleId = async (vehicleId) => {
  const response = await api.get(`${servicePrefix}/traffic-infringements/get-by-vehicle/${vehicleId}`)
  return response.data
}

const OffenceService = {
  //Traffice Infringements for foreigner
  getTrafficInfringementsForForeigner,
  getAllTrafficInfringementsForForeigner,
  getTrafficInfringementsForForeignerById,
  addTrafficInfringementsForForeigner,
  updateTrafficInfringementsForForeigner,
  deleteTrafficInfringementsForForeigner,
  paymentForTrafficInfringementsForForeigner,
  cancelOffenceForForeigner,
  updateTrafficInfringementForeignerAttachment,
  cancellationAttachmentForForeigners,

  //TrafficInfringements for bhutanese
  getTrafficInfringements,
  getAllTrafficInfringements,
  getTrafficInfringementsById,
  addTrafficInfringements,
  updateTrafficInfringements,
  deleteTrafficInfringements,
  paymentForTrafficInfringements,
  cancelOffenceForBhutanese,
  updateTrafficInfringementsAttachment,
  cancelOffenceForBhutaneseAttachments,

  //Inspection Places
  getInspectionPlaces,
  getAllInspectionPlaces,
  getInspectionPlacesById,
  addInspectionPlaces,
  updateInspectionPlaces,
  deleteInspectionPlaces,
  inspectionPlacesByPlaces,

  //Traffic Divisions
  getTrafficDivisions,
  getAllTrafficDivisions,
  getTrafficDivisionsById,
  addTrafficDivisions,
  updateTrafficDivisions,
  deleteTrafficDivisions,

  //Offences
  getOffences,
  getAllOffences,
  getOffencesById,
  addOffences,
  updateOffences,
  deleteOffences,

  getTrafficInfringementsByLicenseNo,
  getTrafficInfringementsByVehicleId,
  getTrafficInfringementsByLicenseOrLearnerNo
}

export default OffenceService
