import { lazy } from 'react'

//Bhutanese
const OffenceSearch = lazy(() => import('../../views/offence/bhutanese/add/index'))
const OffenceDetails = lazy(() => import('../../views/offence/bhutanese/add/OffenceDetails'))
const OffenceFullDetails = lazy(() => import('../../views/offence/bhutanese/add/OffenceFullDetails'))
const OffenceAttachments = lazy(() => import('../../views/offence/bhutanese/add/Attachment'))
const OffenceList = lazy(() => import('../../views/offence/bhutanese/list/index'))
const OffenceView = lazy(() => import('../../views/offence/bhutanese/view/index'))
const OffenceCancellationForBhutanese = lazy(() => import('../../views/offence/bhutanese/view/offence-cancellation/index'))
const OffenceCancellationAttachmentForBhutanese = lazy(() => import('../../views/offence/bhutanese/view/offence-cancellation/Attachment'))
const OffenceEditForBhutanese = lazy(() => import('../../views/offence/bhutanese/view/edit-offence/index'))

//Foreigner
const OffenceListForForeigner = lazy(() => import('../../views/offence/foreigners/list/index'))
const OffenceAdd = lazy(() => import('../../views/offence/foreigners/add/index'))
const OffenceDetailsForForeigners = lazy(() => import('../../views/offence/foreigners/add/OffenceDetails'))
const OffenceFullDetailsForForeigners = lazy(() => import('../../views/offence/foreigners/add/OffenceFullDetails'))
const OffenceViewForForeigners = lazy(() => import('../../views/offence/foreigners/view/index'))
const OffenceAttachmentsForForeigners = lazy(() => import('../../views/offence/foreigners/add/Attachment'))
const OffenceCancellationForForeigners = lazy(() => import('../../views/offence/foreigners/view/offence-cancellation/index'))
const OffenceCancellationAttachmentForForeigners = lazy(() => import('../../views/offence/foreigners/view/offence-cancellation/Attachment'))
const OffenceEditForForeigners = lazy(() => import('../../views/offence/foreigners/view/foreigner-offence-edit/index'))

const OffenceRoutes = [
  {
    path: '/offence/list/bhutanese',
    element: <OffenceList />,
    meta: {
      action: 'read',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/add',
    element: <OffenceSearch />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/offence-details',
    element: <OffenceDetails />,
    meta: {
      action: 'read',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/offence-full-details',
    element: <OffenceFullDetails />,
    meta: {
      action: 'read',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/add/:id/attachments',
    element: <OffenceAttachments />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/view/:id/edit',
    element: <OffenceEditForBhutanese />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/view/:id',
    element: <OffenceView />,
    meta: {
      action: 'read',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/view/:id/cancel-offence',
    element: <OffenceCancellationForBhutanese />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/bhutanese/view/:id/cancellation-attachment',
    element: <OffenceCancellationAttachmentForBhutanese />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },

  //Foreigner
  {
    path: '/offence/list/foreigner',
    element: <OffenceListForForeigner />,
    meta: {
      action: 'read',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/add',
    element: <OffenceAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/add/offence-detail',
    element: <OffenceDetailsForForeigners />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/add/offence-full-detail',
    element: <OffenceFullDetailsForForeigners />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/view/:id',
    element: <OffenceViewForForeigners />,
    meta: {
      action: 'read',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/add/:id/attachments',
    element: <OffenceAttachmentsForForeigners />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/view/:id/cancel-offence',
    element: <OffenceCancellationForForeigners />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/view/:id/cancellation-attachment',
    element: <OffenceCancellationAttachmentForForeigners />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  },
  {
    path: '/offence/list/foreigner/view/:id/edit',
    element: <OffenceEditForForeigners />,
    meta: {
      action: 'manage',
      resource: 'VehicleOffence'
    }
  }
]

export default OffenceRoutes
