// import { XCircle, Circle } from 'react-feather'

// export default [
//   {
//     id: 'offence',
//     title: 'Offences',
//     icon: <XCircle size={20} />,
//     children: [
//       {
//         id: 'bhutanese',
//         title: 'Bhutanese',
//         icon: <Circle size={20} />,
//         navLink: '/offence/list/bhutanese',
//         parentOf: ['/offence/list/bhutanese/add', '/offence/list/bhutanese/offence-details', '/offence/list/bhutanese/offence-full-details', '/offence/list/bhutanese/add/attachments']
//       },
// {
//   id: 'foreigner',
//   title: 'Foreigner',
//   icon: <Circle size={20} />,
//   navLink: '/offence/list/foreigner'
// }
//     ]
//   }
// ]

// ** Icons Import
import { Circle, XCircle } from 'react-feather'

export default [
  {
    id: 'offences',
    title: 'Offences',
    icon: <XCircle size={20} />,
    children: [
      {
        id: 'bhutanese',
        title: 'Bhutanese',
        icon: <Circle size={20} />,
        navLink: '/offence/list/bhutanese',
        parentOf: ['/offence/list/bhutanese/add', '/offence/list/bhutanese/offence-details', '/offence/list/bhutanese/offence-full-details', '/offence/list/bhutanese/add/attachments'],
        action: 'read',
        resource: 'VehicleOffence'
      },
      {
        id: 'foreigner',
        title: 'Foreigner',
        icon: <Circle size={20} />,
        navLink: '/offence/list/foreigner',
        parentOf: ['/offence/list/foreigner/add', '/offence/list/foreigner/add/offence-detail', '/offence/list/foreigner/add/offence-full-detail', '/offence/list/foreigner/add/attachments'],
        action: 'read',
        resource: 'VehicleOffence'
      }
    ]
  }
]
