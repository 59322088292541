// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
// import todo from '@src/views/apps/todo/store'
// import chat from '@src/views/apps/chat/store'
import users from '@src/views/user/store'
// import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
// import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import roles from '@src/views/roles-permissions/roles/store'
import permissions from '@src/views/roles-permissions/permissions/store'
import pisIndividual from '@src/views/personal-information/individual/store'
import pisOrganization from '@src/views/personal-information/organization/store'
import learnerLicenseApplication from '@src/views/learner-license/application/store'
import learnerOnlineApplication from '@src/views/learner-license/learner-license-registration/store'
import learnerLicenses from '@src/views/learner-license/learner-license/store'
import learnerLicenseRenewal from '@src/views/learner-license/learner-license-renewal/store'
import learnerLicenseReplacement from '@src/views/learner-license/learner-license-replacement/store'
import learnerLicenseCancellation from '@src/views/learner-license/learner-license-cancellation/store'
import learnerLicenseSuspension from '@src/views/learner-license/learner-license-suspension/store'
import learnerNumberSequences from '@src/views/masters/learner-number-sequences/store/index'

import drivingLicenseApplications from '@src/views/driving-license/driving-license-application/store/index'
import drivingLicenseDriveTypes from '@src/views/masters/driving-license-drive-types/store/index'
import drivingLicenseLifeSpans from '@src/views/masters/driving-license-life-spans/store/index'
import drivingLicenseEndorsementApplications from '@src/views/driving-license/driving-license-endorsement-application/store/index'
import endorsementCancellations from '@src/views/driving-license/endorsement-cancellation/store/index'
import ordinaryDrivingLicense from '@src/views/driving-license/driving-license-types/ordinary-driving-license/store/index'
import professionalDrivingLicense from '@src/views/driving-license/driving-license-types/professional-driving-license/store/index'
import drivingLicensePrintLists from '@src/views/driving-license/print-list/store/index'
import drivingLicenseCancellations from '@src/views/driving-license/driving-license-types/cancellation/store/index'
import drivingLicenseSuspensions from '@src/views/driving-license/driving-license-types/suspension/store/index'
import drivingLicenseRenewals from '@src/views/driving-license/driving-license-types/renewal/store/index'
import drivingLicenseReplacements from '@src/views/driving-license/driving-license-types/replacement/store/index'
import drivingLicenseNumberSequences from '@src/views/masters/driving-license-number-sequences/store/index'

import personalInfos from '@src/views/personal-information-1/store/searchInfo'
import personalInformationList from '@src/views/personal-information-1/store/datatable'
// import vehicleTables from '@src/views/vehicle/list/store'
// import vehicleFetch from '@src/views/vehicle/services/store/vehicleSlice'
// import vehicleList from '@src/views/vehicle/vehicle-list/store/index'
import countries from '@src/views/masters/country/store/index'
import dzongkhags from '@src/views/masters/dzongkhag/store/index'
import gewogs from '@src/views/masters/gewog/store/index'
import officeLocations from '@src/views/masters/office-location/store/index'
import regions from '@src/views/masters/region/store/index'
import colors from '@src/views/masters/color/store/index'
import dealers from '@src/views/masters/dealer/store/index'
import engineTypes from '@src/views/masters/engine-type/store/index'
import registrationCodes from '@src/views/masters/registration-code/store/index'
import vehicleCompanies from '@src/views/masters/vehicle-company/store/index'
import vehicleModelNames from '@src/views/masters/vehicle-model-name/store/index'
import vehicleModels from '@src/views/masters/vehicle-model-variant/store/index'
import vehicleTypes from '@src/views/masters/vehicle-type/store/index'
import vehicleNumberSequences from '@src/views/masters/registration-number-sequences/store/index'
import nocIssuance from '@src/views/vehicles/vehicle/noc-issuance/store'
import emission from '@src/views/vehicles/vehicle/emissions/store'

import add from '@src/views/personal-information-1/store/index'
import agencies from '@src/views/masters/agency/store/index'
// import vehicleRegistrations from '@src/views/vehicle/store/index'
import learnerLicenseDriveTypes from '@src/views/masters/learner-license-drive-type/store'
import vehicleRegistrations from '@src/views/vehicles/vehicle-registration/application/store/index'
import vehicles from '@src/views/vehicles/vehicle/store/index'
import vehicleFitnesses from '@src/views/masters/fitnesses/store/index'
import vehicleCancellationReasons from '@src/views/masters/cancellation-reasons/store/index'
import vehicleCancellationTypes from '@src/views/masters/cancellation-types/store/index'
import vehicleTransferTypes from '@src/views/masters/transfer-types/store/index'
import vehicleCancellations from '@src/views/vehicles/vehicle/cancellation/store/index'
import vehicleConversionTypes from '@src/views/masters/conversion-types/store/index'
import inspectionPlaces from '@src/views/masters/inspection-places/store/index'
import trafficDivisions from '@src/views/masters/traffic-divisions/store/index'
import offences from '@src/views/masters/offences/store/index'
import trafficInfringements from '@src/views/offence/bhutanese/store/index'
import trafficInfringementsForForeigner from '@src/views/offence/foreigners/store/index'
import routeList from '@src/views/masters/route-lists/store/index'
import vehiclePermits from '@src/views/vehicles/vehicle/vehicle-permit/store/index'
import passengerBusInspections from '@src/views/vehicles/vehicle/passenger-bus-inspection/store/index'
import busCategoryTypes from '@src/views/masters/bus-category-types/store/index'
import vehicleLifeSpans from '@src/views/masters/vehicle-life-spans/store/index'
import vehicleRenewals from '@src/views/vehicles/vehicle/renewal/store/index'
import vehicleReplacements from '@src/views/vehicles/vehicle/replacement/store/index'
import vehicleOwnershipTransfers from '@src/views/vehicles/vehicle/ownership-transfer/store/index'
import drivingLicensePunch from '@src/views/driving-license/license-punch/store/index'

//RWC
import fitness from '@src/views/vehicles/vehicle/fitness/store/index'

//eTEST
import drivingTestConfigurations from '@src/views/driving-test/test-configuration/store/index'
import practicalTestCandidates from '@src/views/driving-test/test-candidate/store/index'
import drivingTestQuestions from '@src/views/driving-test/question/store/index'

//payments
import payment from '@src/views/payment/store/index'

//crash
import vehicleActions from '@src/views/vehicle-crash-data/masters/vehicle-action/store/index'
import safetySystems from '@src/views/vehicle-crash-data/masters/safety-system/store/index'
import driverBehaviours from '@src/views/vehicle-crash-data/masters/driver-behaviour/store/index'
import trafficControlDevices from '@src/views/vehicle-crash-data/masters/traffic-control-device/store/index'
import collisionTypes from '@src/views/vehicle-crash-data/masters/collision-type/store/index'
import nonCollisionTypes from '@src/views/vehicle-crash-data/masters/non-collision-type/store/index'
import crashInformations from '@src/views/vehicle-crash-data/store/index'
import roadSurfaceConditions from '@src/views/vehicle-crash-data/masters/road-surface-condition/store/index'
import divisions from '@src/views/vehicle-crash-data/masters/division/store/index'
import stations from '@src/views/vehicle-crash-data/masters/station/store/index'
import locationTypes from '@src/views/vehicle-crash-data/masters/location-type/store/index'
import trafficCongestionConditions from '@src/views/vehicle-crash-data/masters/traffic-congestion-condition/store/index'
import reportConfigurations from '@src/views/reports/report-configuration/store/index'
import reports from '@src/views/reports/generate-report/store/index'
import ejectionCodes from '@src/views/vehicle-crash-data/masters/ejection-code/store/index'
import insuranceTypes from '@src/views/vehicle-crash-data/masters/insurance-type/store/index'
import drugTypes from '@src/views/vehicle-crash-data/masters/drug-type/store/index'
import injuryStatus from '@src/views/vehicle-crash-data/masters/injury-status/store/index'
import damageAreas from '@src/views/vehicle-crash-data/masters/damage-area/store/index'
import nonMotoristTypes from '@src/views/vehicle-crash-data/masters/non-motorist-type/store/index'
import nonMotoristActions from '@src/views/vehicle-crash-data/masters/non-motorist-action/store/index'
import nonMotoristLocations from '@src/views/vehicle-crash-data/masters/non-motorist-location/store/index'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  auth,
  users,
  roles,
  pisIndividual,
  pisOrganization,

  drivingLicenseApplications,
  drivingLicenseDriveTypes,
  drivingLicenseLifeSpans,
  drivingLicenseEndorsementApplications,
  endorsementCancellations,
  ordinaryDrivingLicense,
  professionalDrivingLicense,
  drivingLicensePrintLists,
  drivingLicenseCancellations,
  drivingLicenseSuspensions,
  drivingLicenseRenewals,
  drivingLicenseReplacements,
  drivingLicenseNumberSequences,

  learnerLicenseApplication,
  learnerOnlineApplication,
  learnerLicenseDriveTypes,
  learnerLicenses,
  learnerLicenseRenewal,
  learnerLicenseReplacement,
  learnerLicenseCancellation,
  learnerLicenseSuspension,
  learnerNumberSequences,
  navbar,
  layout,
  invoice,
  // calendar,
  ecommerce,
  // dataTables,
  permissions,
  personalInfos,
  personalInformationList,
  // vehicleTables,
  // vehicleFetch,
  // vehicleList,
  countries,
  dzongkhags,
  gewogs,
  officeLocations,
  regions,
  add,
  colors,
  dealers,
  engineTypes,
  registrationCodes,
  vehicleCompanies,
  vehicleModelNames,
  vehicleModels,
  vehicleTypes,
  agencies,
  vehicleRegistrations,
  vehicles,
  vehicleFitnesses,
  vehicleCancellationReasons,
  vehicleCancellationTypes,
  inspectionPlaces,
  trafficDivisions,
  vehicleTransferTypes,
  vehicleCancellations,
  vehicleConversionTypes,
  vehicleNumberSequences,
  offences,
  trafficInfringements,
  trafficInfringementsForForeigner,
  routeList,
  busCategoryTypes,
  vehiclePermits,
  passengerBusInspections,
  vehicleLifeSpans,
  vehicleRenewals,
  vehicleReplacements,
  nocIssuance,
  vehicleOwnershipTransfers,
  drivingLicensePunch,

  //fitness
  fitness,
  emission,

  //DRIVING TEST STATES
  drivingTestConfigurations,
  practicalTestCandidates,
  drivingTestQuestions,

  //payments
  payment,

  //vehicle-crash
  vehicleActions,
  safetySystems,
  driverBehaviours,
  trafficControlDevices,
  collisionTypes,
  nonCollisionTypes,
  crashInformations,
  roadSurfaceConditions,
  divisions,
  stations,
  locationTypes,
  trafficCongestionConditions,
  reportConfigurations,
  reports,
  ejectionCodes,
  insuranceTypes,
  injuryStatus,
  damageAreas,
  nonMotoristTypes,
  nonMotoristActions,
  nonMotoristLocations,
  drugTypes
})

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
