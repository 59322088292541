import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import OffenceService from '@services/offence'

//Get Offences
export const getOffences = createAsyncThunk('offences/getOffences', async (params) => {
  const response = await OffenceService.getOffences(params)
  return { params, data: response.data, totalPages: response.meta.itemCount }
})

//Post new Offences
export const addOffences = createAsyncThunk('offences/addOffences', async (offencesData, { rejectWithValue }) => {
  try {
    const response = await OffenceService.addOffences(offencesData)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//Update Offences
export const updateOffences = createAsyncThunk('offences/updateOffences', async (offencesData, { rejectWithValue }) => {
  try {
    const reponse = await OffenceService.updateOffences(offencesData)
    return reponse
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//Delete Offences
export const deleteOffences = createAsyncThunk('offences/deleteOffences', async (id, { getState, dispatch }) => {
  await OffenceService.deleteOffences(id)
  await dispatch(getOffences(getState()?.offences?.params))
  return id
})
const OffencesSlice = createSlice({
  name: 'offences ',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  extraReducers(builder) {
    builder
      .addCase(getOffences.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateOffences.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          return
        }
        const { id } = action.payload
        const offences = state.data.filter((offences) => offences.id !== id)
        state.data = [...offences, action.payload]
      })
  }
})

export const selectoffencesById = (state, id) => state.offences.data.find((offences) => offences.id === id)
export default OffencesSlice.reducer
